<template>
  <div class="message__text">
    <div class="message__content">
      <span>{{ messageText }}</span>
    </div>
    <div
      v-if="showOverflowButton"
      @click="showMore"
      class="message__button"
      role="button">leer más
    </div>
  </div>
</template>

<script>
export default {
  props: {
    message: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      hideLargeText: true,
    };
  },
  computed: {
    messageText() {
      if (this.showOverflowButton) {
        return `${this.capMessage(this.message.text)}...`;
      }

      return this.message.text;
    },
    textOverflows() {
      return this.message.text && this.message.text.length > 500
        && this.message.text.length - 500 > 50;
    },
    showOverflowButton() {
      return this.textOverflows && this.hideLargeText;
    },
  },
  methods: {
    capMessage(str) {
      return str.substring(0, 500);
    },
    showMore() {
      this.hideLargeText = false;
    },
  },
};
</script>

<style scoped lang="scss">
  @import '~styles/components/chat/_message.scss';
</style>
